import React from 'react';
import { Link } from 'react-router-dom';

import SwiperCore, { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import './style.css';
import sliderImg1 from '../../assets/img/slider/frame.png';
import shapeImg1 from '../../assets/img/illustrations/polaroid.svg';
import shapeImg2 from '../../assets/img/slider/shape-6.png';
import shapeImg3 from '../../assets/img/slider/shape-14.png';
import shapeImg4 from '../../assets/img/slider/shape-11.png';
import shapeImg5 from '../../assets/img/slider/shape-5.png';
import shapeImg6 from '../../assets/img/slider/shape-13.png';
import shapeImg7 from '../../assets/img/slider/shape-black.png';

import frame1 from '../../assets/img/slider/frame1.jpg';
import frame2 from '../../assets/img/slider/frame2.jpg';
import frame3 from '../../assets/img/slider/frame3.jpg';
import frame4 from '../../assets/img/slider/frame4.jpg';
import frame5 from '../../assets/img/slider/frame5.jpg';
import frame6 from '../../assets/img/slider/frame6.jpg';
import frame7 from '../../assets/img/slider/frame7.jpg';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);
SwiperCore.use([EffectCoverflow]);
SwiperCore.use([Autoplay]);


const Banner = () => {

    return (
        <div className="slider-2__area grey-bg slider-2__space slider-2__plr p-relative z-index fix">
            <div className="slider-2__shape-2 d-none d-md-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="slider-2__shape-3 d-none d-xxl-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="slider-2__shape-4 d-none d-xxl-block">
                <img src={shapeImg4} alt="" />
            </div>
            <div className="slider-2__shape-5 d-none d-xl-block">
                <img src={shapeImg5} alt="" />
            </div>
            <div className="slider-2__shape-6 d-none d-xl-block">
                <img src={shapeImg6} alt="" />
            </div>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={false}
                loop={true}
                autoplay={{ delay: 2000 }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={frame1} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame2} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame3} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame4} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame5} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame6} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={frame7} />
                </SwiperSlide>

            </Swiper>
            <div className="row align-items-center frame-absolute">
                <div className="col-xl-12 col-lg-12">
                    <div className="slider-2__wrapper z-index">
                        <div className="slider-2__btn wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="slider-2__thumb text-center">
                                <img className='frame' src={sliderImg1} alt="" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='hero'>
                    <h1>Frame Your Memories for a Lifetime</h1>
                    <br/>
                    <Link to="/design" className="main-btn main-title tp-btn-hover alt-color">

                        <span>Design Your Frame</span>
                        <b></b>
                    </Link>
                </div>
            </div>


        </div>
    );
}

export default Banner;