import React from 'react';
import ProductMain from './ProductMain';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';

const Product = () => {
  return (
    <>
      <Header
        parentMenu="page"
      />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <ProductMain />
        </div>
      </div>
          
    </>
  );
}

export default Product;
