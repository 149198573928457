import React, { useState, useCallback, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import './frame.css';
import { FaPalette, FaArrowsAltH, FaPaintBrush, FaCrop } from 'react-icons/fa';


const ProductMain = () => {
	const [image, setImage] = useState(null);
	const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: 1 });
	const [completedCrop, setCompletedCrop] = useState(null);
	const [color, setColor] = useState('Dark');
	const [size, setSize] = useState('8" X 8"');
	const [finish, setFinish] = useState('Normal');
	const [activeOption, setActiveOption] = useState('');
	const [backgroundSize, setBackgroundSize] = useState('cover');
	const options = [
		{ name: 'Color', icon: <FaPalette /> },
		{ name: 'Size', icon: <FaArrowsAltH /> },
		{ name: 'Finish', icon: <FaPaintBrush /> },
		{ name: 'Crop', icon: <FaCrop /> },
	];

	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const optionsPanelRef = useRef(null);
	const cropperRef = useRef(null);
	const [cropper, setCropper] = useState(null);
	const [originalImage, setOriginalImage] = useState(null);
	const [zoomLevel, setZoomLevel] = useState(1);

	const handleImageUpload = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
				reader.addEventListener('load', () => {
				setOriginalImage(reader.result);
				setImage(reader.result);
				if (cropper) {
					cropper.reset();
				}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);



	const sizeOptions = [
		{ size: '5" X 7"', price: 682 },
		{ size: '7" X 5"', price: 682 },
		{ size: '6" X 8"', price: 399 },
		{ size: '8" X 6"', price: 399 },
		{ size: '8" X 8"', price: 504 },
		{ size: '12" X 8"', price: 504 },
		{ size: '8" X 12"', price: 682 },
	];

	function formatFrame(input) {
		var baseDimension = 300
		// Remove quotes and spaces
		let formatted = input.replace(/[" ]/g, '');

		// Replace 'X' with 'f' at the start
		if (formatted.includes('X')) {
			formatted = 'f' + formatted.replace('X', 'X');
		}
		const regex = /f(\d+)X(\d+)/;
		const match = formatted.match(regex);

		if (match) {
			const height = parseInt(match[1]);
			const width = parseInt(match[2]);
			const aspectRatio = width / height;

			// Apply the base dimension to the width and adjust height accordingly
			const calculatedWidth = baseDimension;
			const calculatedHeight = baseDimension / aspectRatio;

			return {
				height: `${calculatedHeight}px`,
				width: `${calculatedWidth}px`,
			};
		}

		return {};


		return formatted;
	}

	const colorOptions = ['Dark', 'Light', 'Beige'];
	const finishOptions = ['Normal', 'Matte', 'Glossy'];

	const parseSize = (sizeStr) => {
		const [dim1, dim2] = sizeStr.replace(/[" ]/g, '').split('X').map(num => parseInt(num));
		// If first number is larger, it's a vertical frame
		const isVertical = dim1 > dim2;
		return {
			width: isVertical ? dim2 : dim1,
			height: isVertical ? dim1 : dim2
		};
	};

	useEffect(() => {
		if (!image) return;

		const img = new Image();
		img.src = image;

		img.onload = () => {
			const imgAspectRatio = img.width / img.height;
			const { width, height } = parseSize(size);
			const frameAspectRatio = width / height;

			if (imgAspectRatio > frameAspectRatio) {
				setBackgroundSize(`${(imgAspectRatio / frameAspectRatio) * 100}% contain`);
			} else {
				setBackgroundSize(`contain ${(frameAspectRatio / imgAspectRatio) * 100}%`);
			}
		};
	}, [image, size]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (optionsPanelRef.current && !optionsPanelRef.current.contains(event.target)) {
				setActiveOption(null);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const getCropData = () => {
		if (cropper) {
			const croppedCanvas = cropper.getCroppedCanvas();
			setImage(croppedCanvas.toDataURL());
			setActiveOption('');
			setBackgroundSize('cover');
		}
	};

	useEffect(() => {
		if (cropper) {
			const { width, height } = parseSize(size);
			cropper.setAspectRatio(width / height);
		}
	}, [size, cropper]);

	const handleZoom = (e) => {
		if (cropper) {
			const newZoomLevel = parseFloat(e.target.value);
			setZoomLevel(newZoomLevel);
			cropper.zoomTo(0.1*newZoomLevel);
		}
	};

	const resetCrop = () => {
		if (cropper) {
			setImage(originalImage);
			setZoomLevel(1);
			cropper.reset();
		}
	};

	return (
		<div className="product-designer">


			<div className="frame-preview-column">
				<div>
					<div>
						{!image ? (
							<div className="upload-frame">
								<div className="upload-area">
									<input
										type="file"
										id="file-upload"
										className="file-input"
										accept="image/*"
										onChange={handleImageUpload}
									/>
									<label htmlFor="file-upload" className="upload-button">
										<i className="fas fa-cloud-upload"></i>
									</label>
									<p className="drag-drop-text">or drag and drop here</p>
								</div>
							</div>
						) : (
							<div
								className={`frame ${color.toLowerCase()}`}
								style={{
									...formatFrame(size),
									backgroundImage: `url(${image})`,
									backgroundSize: backgroundSize,
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat'
								}}
							/>
						)}
					</div>
				</div>
			</div>
			{image && <>
				<div className="frame-details-column">
					<h2>Frame Details</h2>
					<div className="detail-item">
						<span className="detail-icon color-icon"></span>
						<span className="detail-label">Color</span>
						<span className="detail-value">{color}</span>
					</div>
					<div className="detail-item">
						<span className="detail-icon size-icon"></span>
						<span className="detail-label">Size</span>
						<span className="detail-value">{size}</span>
					</div>
					<div className="detail-item">
						<span className="detail-icon finish-icon"></span>
						<span className="detail-label">Finish</span>
						<span className="detail-value">{finish}</span>
					</div>
					<div className="price-details">
						<div className="item-price">
							<span>Item Price</span>
							<span>₹{sizeOptions.find(s => s.size === size)?.price || 399}</span>
						</div>
						<div className="grand-total">
							<span>Grand Total</span>
							<span>₹{sizeOptions.find(s => s.size === size)?.price || 399}</span>
						</div>
					</div>
					<button className="add-to-cart">Add to Cart</button>
				</div>
				<div className="bottom-navbar">
					<div className="navbar-options">
						{options.map(option => (
							<button
								key={option.name}
								className={`navbar-button ${activeOption === option.name ? 'active' : ''}`}
								onClick={() => setActiveOption(option.name)}
							>
								{option.icon}
								<span className="navbar-label">{option.name}</span>
							</button>
						))}
						<div className="navbar-slider" style={{ transform: `translateX(${options.findIndex(opt => opt.name === activeOption) * 100}%)` }} />
					</div>

				</div>


				<div ref={optionsPanelRef} className={`options-show ${activeOption ? 'slide-up' : ''}`}>
					{activeOption === 'Color' && (
						<div className="color-selection">
							<h2>Select Color</h2>
							{colorOptions.map((colorOption) => (
								<button
									key={colorOption}
									className={`color-option ${color === colorOption ? 'selected' : ''}`}
									onClick={() => setColor(colorOption)}
								>
									{colorOption}
								</button>
							))}
						</div>
					)}

					{activeOption === 'Size' && (
						<div className="size-selection">
							<h2>Select Size (Inches)</h2>
							{sizeOptions.map(({ size: sizeOption, price }) => (
								<button
									key={sizeOption}
									className={`size-option ${size === sizeOption ? 'selected' : ''}`}
									onClick={() => setSize(sizeOption)}
								>
									<span className="size-icon"></span>
									<span className="size-text">{sizeOption}</span>
									<span className="size-price">₹{price}</span>
								</button>
							))}
						</div>
					)}

					{activeOption === 'Finish' && (
						<div className="finish-selection">
							<h2>Select Finish</h2>
							{finishOptions.map((finishOption) => (
								<button
									key={finishOption}
									className={`finish-option ${finish === finishOption ? 'selected' : ''}`}
									onClick={() => setFinish(finishOption)}
								>
									{finishOption}
								</button>
							))}
						</div>
					)}

					{activeOption === 'Crop' && (
						<div className="crop-instructions">
							<h2>Crop Image</h2>
							<div style={formatFrame(size)} className='center-crop'>
								<Cropper
									ref={cropperRef}
									src={image}
									style={{ height: '100%', width: '100%' }}
									aspectRatio={parseSize(size).width / parseSize(size).height}
									guides={true}
									viewMode={2}
									dragMode="move"
									background={false}
									cropBoxMovable={false}
									cropBoxResizable={false}
									zoomable={true}
									zoomOnWheel={false}
									onInitialized={(instance) => setCropper(instance)}
									autoCropArea={1}
								/>
							</div>
							<div className="crop-controls">
								<div className="zoom-control">
									<span className="zoom-label">0.1</span>
									<input
										type="range"
										min="1"
										max="10"
										step="0.01"
										value={zoomLevel}
										onChange={handleZoom}
										className="zoom-slider"
									/>
									<span className="zoom-label">3x</span>
								</div>
								<button onClick={resetCrop} className="crop-control-button">
									<i className="fas fa-undo"></i>
								</button>
								<button onClick={getCropData} className="apply-crop-button">
									Apply Crop
								</button>
							</div>
						</div>
					)}

				</div>
			</>
			}
		</div >
	);
}

export default ProductMain;