import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/SectionTitle'
import SingleService from '../../components/Service';

import shapeImg1 from '../../assets/img/service/sv-shape-1.png';
import shapeImg2 from '../../assets/img/service/sv-shape-2.png';

import serviceIconBg1 from '../../assets/img/service/sv-icon-shape-1.png'
import serviceIconBg2 from '../../assets/img/service/sv-icon-shape-2.png'
import serviceIconBg3 from '../../assets/img/service/sv-icon-shape-3.png'
import serviceIconBg4 from '../../assets/img/service/sv-icon-shape-4.png'
import serviceIconBg5 from '../../assets/img/service/sv-icon-shape-5.png'
import serviceIconBg6 from '../../assets/img/service/sv-icon-shape-6.png'
import step1 from '../../assets/img/illustrations/step1.svg'
import step2 from '../../assets/img/illustrations/step2.svg'
import step3 from '../../assets/img/illustrations/step3.svg'
import step4 from '../../assets/img/illustrations/step4.svg'

const Service = () => {

    return (
        <div className="service__area service__space-2 black-bg p-relative">
            <div className="service__shape-1">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="service__shape-2">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-6 wow animate__fadeInUp" data-wow-duration="1.1s">
                        <SectionTitle
                            sectionClass= "service__section-box text-center pb-35"
                            titleClass= "section-title text-white title-anim"
                            Title= "Your Memories, Delivered at your Doorstep"
                        />
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-3 bg-color p-relative fix"
                            btnClass= "service__link service__link-color-3"
                            serviceIconBg= {step1}
                            Title= "Step 1 - Upload Your Photos"
                            Description= "Upload your favorite and memories that you wish to cherish for a lifetime"
                            btnText= "Read More"
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-2 bg-color p-relative fix"
                            btnClass= "service__link service__link-color-2"
                            serviceIconBg= {step2}
                            Title= "Step 2 - Design Your Frame"
                            Description= "Customize your frame by choosing from different frame sizes, colors and photo finishinmg styles"
                            btnText= "Read More"
                        />
                    </div>
                   
                    <div className="col-xl-3 col-lg-3 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-4 bg-color p-relative fix"
                            btnClass= "service__link service__link-color-4"
                            serviceIconBg= {step3}
                            Title= "Step 3 - Place Order"
                            Description= "add the frames to the cart and complete the purchase through the website securely"
                            btnText= "Read More"
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s"
                        >
                        <SingleService
                            itemClass= "service__item service__border-1 bg-color p-relative fix"
                            btnClass= "service__link service__link-color-1"
                            serviceIconBg= {step4}
                            Title= "Step 4 - Your Frames at Your Doorstep"
                            Description= "Sit back and relax. we will deliver your personalized frames at your doorstep"
                            btnText= "Read More"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;