import React, { useEffect, useRef, useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/img/about/about-2.png';
import shapeImg1 from '../../assets/img/about/about-shape-3.png';
import shapeImg2 from '../../assets/img/about/about-shape-4.png';
import shapeImg3 from '../../assets/img/about/about-shape-5.png';
import './about.css'

const About = () => {

    const [isVisible, setIsVisible] = useState(false);
    const crackRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, {
            threshold: 0.1 // Adjust this as needed
        });

        if (crackRef.current) {
            observer.observe(crackRef.current);
        }

        return () => {
            if (crackRef.current) {
                observer.unobserve(crackRef.current);
            }
        };
    }, []);

    return (
        <div className="about-2__area pt-100 pb-130 p-relative fix">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6">
                        <div className={`wall ${isVisible ? 'visible' : ''}`} ref={crackRef}>
                            <div class="nail">
                                <div class="nail-head"></div>
                                <div class="nail-shadow"></div>
                            </div>
                            <div class="crack crack1"></div>
                            <div class="crack crack2"></div>
                            <div class="crack crack3"></div>
                            <div class="crack crack4"></div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="about-2__item">
                            <div className="about-2__section-title">
                                <h3 className="section-title char-anim">No More Cracked Walls</h3>
                            </div>
                            <div className="about-2__text">
                                <p className="char-anim-2">Our frames are effortless to install. Just peel off the backing tape and stick the frame to your wall in under 10 seconds.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;